import React, { createRef } from "react";

import moment from "moment";
import "bootstrap-daterangepicker";
import "./daterangepicker.css";

class DateRangePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const opts = {
      showDropdowns: true,
      timePicker: false,
      timePicker24Hour: true,
      timePickerSeconds: false,
      ranges: {
        Today: [moment().startOf("day"), moment()],
        Yesterday: [moment().subtract(1, "days"), moment()],
        "This Week": [moment().startOf("week"), moment()],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 30 Days": [moment().subtract(30, "days"), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment().startOf("month").subtract(1, "months"),
          moment().startOf("month"),
        ],
      },
      linkedCalendars: false,
      startDate: moment(),
      opens: "center",
      buttonClasses: "small ui button",
      applyClass: "primary",
      cancelClass: " ",
    };
    Object.assign(opts, this.props.options);

    window
      .$('input[name="dateRange"]')
      .daterangepicker(opts, (start, end, label) => {
        if (this.props.onApply) this.props.onApply(start, end, label);
      });
  }
  componentWillUnmount() {
    window.$('input[name="dateRange"]').data("daterangepicker").remove();
  }

  wrapper = createRef();

  render() {
    return <input type="text" name="dateRange" />;
  }
}
export default DateRangePicker;
