import React, { useState, useEffect } from "react";
import { Checkbox } from "semantic-ui-react";

const FieldAndCheckboxCombo = ({ field, setField, label }) => {
  const [fieldChecked, setFieldChecked] = useState(field ? true : false);
  const [fieldError, setFieldError] = useState(false);

  useEffect(() => {
    if (validateAddress(field)) {
      setFieldError(false);
      setFieldChecked(true);
    } else {
      setFieldError(field ? true : false);
      setFieldChecked(false);
    }
  }, [field, fieldChecked]);

  const validateAddress = (address) => {
    if (!address) {
      address = "";
    }
    let val = address.trim();
    let addr = val.match(/(\d)+(\/(\d)+){1,2}/g);
    if (addr) {
      if (Array.isArray(addr)) addr = addr[0];
      else addr = undefined;
    }
    return addr ? true : false;
  };

  return (
    <div className="inline field">
      <Checkbox
        checked={fieldChecked}
        onChange={() => setFieldChecked(!fieldChecked)}
        label={label}
      />
      <div className="ui small input">
        <input
          className={fieldError ? "location-step-error-field" : ""}
          type="text"
          placeholder="1/2/3"
          defaultValue={field}
          onChange={(e) => setField(e.target.value)}
        />
      </div>
    </div>
  );
};

export default FieldAndCheckboxCombo;
