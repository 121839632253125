import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Icon } from "semantic-ui-react";

import { api } from "../api";
import "../styles/login.css";

const Login = ({ setLoggedIn }) => {
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const login = (e) => {
    e.preventDefault();
    setIsLoading(true);

    api("/authenticate", {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body:
        encodeURIComponent("user") +
        "=" +
        encodeURIComponent(user) +
        "&" +
        encodeURIComponent("pass") +
        "=" +
        encodeURIComponent(pass),
    })
      .then((res) => {
        if (res.success === true) {
          window.accessToken = res.accessToken;
          localStorage.setItem("accessToken", res.accessToken);
          setLoggedIn(true);
        } else {
          var error = new Error(res.error);
          error.message = "Invalid Credential";
          throw error;
        }
      })
      .catch((ex) => {
        setError(ex.message);
        setIsLoading(false);
      });
  };

  return (
    <div className="imagy">
      <div id="header">
        <h1>
          <a href="/">
            KNX<span style={{ color: "black" }}>Monitor</span>
          </a>
        </h1>
      </div>
      <div className="container">
        <form id="RegForm" className="ui form segment">
          <h2 className="ui center aligned icon header">
            <Icon name="sign in" />
            Log In
          </h2>
          <div className="field">
            <label>
              Email
              <input
                type="text"
                value={user}
                onChange={(e) => {
                  setUser(e.target.value);
                }}
                placeholder="Username"
              />
            </label>
          </div>
          <div className="field">
            <label>
              Password
              <input
                type="password"
                value={pass}
                onChange={(e) => {
                  setPass(e.target.value);
                }}
                placeholder="Password"
              />
            </label>
          </div>
          <div className="ui center aligned header">
            <button
              className={`ui primary button ${
                isLoading ? "loading disabled" : ""
              }`}
              style={{ minWidth: "10em" }}
              type="submit"
              onClick={(e) => login(e)}
            >
              Login
            </button>
            {error.length > 0 ? (
              <div className="ui left pointing red basic label">{error}</div>
            ) : undefined}
          </div>
        </form>

        <div className="ui center aligned header rows">
          <small>
            Forgotten your password? <Link to="/password-reset">Reset</Link>.
          </small>
          <small>
            Don&apos;t have an account?{" "}
            <a href="https://login.knxmonitor.com/signin/register">
              Create one
            </a>
            .
          </small>
        </div>
      </div>
    </div>
  );
};

export default Login;
