import React, { createRef } from "react";

class GroupComboBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      groupTree: [],
    };
  }

  setUpDropdown() {
    var domNode = this.wrapper.current;
    //generate tree strucutre for multi level group addresses
    //this.state.groupTree = this.genTree(this.state.groups)

    var opts = {
      data: this.state.groups, //this.state.groupTree,
      multiple: false,
      placeholder: "Select a Group...",
    };
    Object.assign(opts, this.props.options);

    let settingUp = true;
    window
      .$(domNode)
      .find(".select.field")
      .append("<select id='groupSelect' name='selectGroup'><option/></select>");
    window
      .$(domNode)
      .find("select")
      .select2(opts)
      .on("select2:select", (e) => {
        if (settingUp) return;

        //console.log(e.params.data)
        this.selectedGroup = e.params.data.id;
        if (this.props.onChange) this.props.onChange(this.selectedGroup);
        // $(domNode).find('select').val(e.params.data.id).trigger('change')
      });

    if (this.props.defaultValue) {
      window
        .$(domNode)
        .find("select")
        .val(this.props.defaultValue)
        .trigger("change");
    }

    settingUp = false;
  }

  componentDidMount() {
    if (this.props.groups == undefined) return;

    //translate groups into group collection
    this.setState(
      {
        groups: Object.keys(this.props.groups)
          .map((key) => {
            let grp = this.props.groups[key];
            if (grp.isRange) return undefined;
            return {
              id: key,
              text: key + " " + grp.name,
            };
          })
          .filter(Boolean),
      },
      this.setUpDropdown
    );
  }
  componentWillUnmount() {
    var domNode = this.wrapper.current;
    window.$(domNode).find("select").select2("destroy");
  }

  wrapper = createRef();

  render() {
    return (
      <div ref={this.wrapper}>
        <div className="ui form">
          <div className="inline fields">
            <div className="select field" />
            <div className="field">
              {this.props.groups == undefined ? (
                "No group address found, please import ETS project file in settings."
              ) : (
                <button
                  className="ui small primary button"
                  style={this.props.buttonStyle}
                  onClick={() => {
                    if (this.selectedGroup && this.props.onSelect)
                      this.props.onSelect(this.selectedGroup);
                  }}
                >
                  ADD
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GroupComboBox;
