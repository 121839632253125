var Unsupported = require("./DPTUnsupported");

module.exports = function (decoder) {
  if (!decoder) return Unsupported;
  if (decoder.name)
    switch (decoder.name) {
      case "Scaling":
        return require("./DPTPercentage");
      case "HVACMode":
      case "HVACContrMode":
      case "HeaterMode":
      case "FanMode":
      case "BuildingMode":
      case "DamperMode":
        return require("./DPTHVACMode");
    }

  if (decoder.converter)
    switch (decoder.converter) {
      case "DPTBoolean":
        return require("./DPTBoolean");
      case "DPT2ByteFloat":
        return require("./DPT2ByteFloat");
    }

  return Unsupported;
};
