import React from "react";
import { loadStripe } from "@stripe/stripe-js/pure";
import { api } from "../api";

const SubscriptionOption = ({
  name,
  max_active_buildings,
  max_timers,
  max_alerts,
  prices,
  hasStripe,
  isCurrentPlan,
  free_trial_days,
}) => {
  const getCustomerPortalUrl = () => {
    api("/payments/customer-portal", { method: "POST" }).then(
      (data) => (window.location.href = data.url)
    );
  };

  const getCheckoutSessionUrl = async (price_id) => {
    const stripeDetails = await api("/payments/stripe");

    /* Only load stripe.js when we hit the checkout button, otherwise it sends telemetry to Stripe every time you change
    page or move the mouse for "fraud detection".
    See https://mtlynch.io/stripe-recording-its-customers/
    and https://stripe.com/docs/disputes/prevention/advanced-fraud-detection#disabling-advanced-fraud-detection
    - we use the /pure version of the js library so that Stripe doesn't start tracking all the page views and mouse 
    movements until the customer presses the checkout button and reaches this point in the code. 
    Tracking might continue after the customer has checked out unless you uncomment the next line too.
    */
    // loadStripe.setLoadParameters({advancedFraudSignals: false});
    const stripe = await loadStripe(stripeDetails.publishable_key);

    const response = await api("/payments/create-checkout-session", {
      method: "POST",
      body: JSON.stringify({ price_id: price_id }),
    });

    // Error silently if it can't get a session id from stripe
    if (response.sessionId === undefined) {
      return;
    }

    await stripe.redirectToCheckout({ sessionId: response.sessionId });
  };

  return (
    <div className="pricing-container">
      <h3>{name}</h3>

      {hasStripe && (
        <div className="price">
          <div className="price-figure">
            {prices.map(({ price, tenure, metadata }) => (
              <div key={price} title={metadata.price_info}>
                <span className="price-number">{price}</span>
                <span className="price-tenure">{tenure}</span>
              </div>
            ))}
          </div>
        </div>
      )}
      <ul className="features">
        <li title="Upload as many projects as you want to the site">
          Unlimited Projects
        </li>
        <li title="The number of buildings being monitored concurrently">
          {max_active_buildings} Active Building
          {max_active_buildings === 1 ? "" : "s"}
        </li>
        <li title="These are email alerts you can configure based on logic">
          {max_alerts === 0
            ? "No Alerts"
            : max_alerts === null
            ? "Unlimited Alerts"
            : max_alerts === 1
            ? "1 Alert per Building"
            : max_alerts + " Alerts per Building"}
        </li>
        <li title="Timers can be absolute or offset to Sunrise or Sunset. Daylight saving is automatically applied">
          {max_timers === 0
            ? "No Timers"
            : max_timers === null
            ? "Unlimited Timers"
            : max_timers === 1
            ? "1 Timer per Building"
            : max_timers + " Timers per Building"}
        </li>
        <li title="We endeavour to get back to you within 24 hours">
          Email support
        </li>
      </ul>
      <div className="footer">
        {free_trial_days > 0 && !hasStripe && !isCurrentPlan && (
          <div className="free-trial-list-item">
            {free_trial_days} days free trial
          </div>
        )}
        {hasStripe && !isCurrentPlan && (
          <button
            className="btn btn-inverse btn-block"
            onClick={() => getCustomerPortalUrl()}
          >
            Manage Subscription
          </button>
        )}
        {!hasStripe &&
          !isCurrentPlan &&
          prices.map(({ price, tenure, id }) => (
            <button
              className="btn btn-inverse btn-block"
              onClick={() => getCheckoutSessionUrl(id)}
              key={price}
            >
              {`${price} ${tenure ? tenure : ""}`}
            </button>
          ))}
      </div>
    </div>
  );
};

export default SubscriptionOption;
