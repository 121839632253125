import React, { createRef } from "react";
import { Icon } from "semantic-ui-react";
import { api , ws } from "../../api";

const connectionButtonStyle = {
  position: "absolute",
  top: "-4.5em",
  right: 0,
};

const helpButtonStyle = {
  position: "absolute",
  top: "-4.5em",
  right: "150px",
};

const saveChangeButtonStyle = {
  position: "absolute",
  right: "1em",
  bottom: "-5.4em",
  borderTop: "none",
  borderRadius: "0 0px 5px 5px",
};

class ConnectionStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vpn: props.building.vpn || false,
      vpnFile: null, // State to hold the selected VPN file
      vpn_file_content: props.building.vpn_file_content || "", // State to hold the VPN file content
      vpn_info: props.building.vpn_info || {}, // State to hold the parsed VPN info 
      connectionStatus: null,  // Track connection status
      terminalLogs: [],  // Store WebSocket messages
    };
    this.socket = null;
    this.terminalRef = React.createRef();
  }

  componentDidMount() {
    this.setupWebSocket();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.building !== this.props.building) {
      this.setState({
        vpn: this.props.building.vpn || false,
        vpn_file_content: this.props.building.vpn_file_content || "",
        vpn_info: this.props.building.vpn_info || {},
      });

      // Re-subscribe if the building changes
      if (this.socket) {
        this.socket.emit("subscribe", {
          building: this.props.building._id,
        });
      }
    }
  }

  setupWebSocket() {
    // Use the shared ws function to initialize the WebSocket
    ws().then((socket) => {
      this.socket = socket;

      // Subscribe to the status updates for the current building
      this.socket.emit("subscribe", {
        building: this.props.building._id,
      });

      // Listen for 'status' events from the server
      this.socket.on("status", (data) => {


        console.log(data);

          const datasource =  data.daemon || data.daemon_name || data.source || "unknown" ;
        // if (data.status === "openvpn_statistics" || data.status === "heartbeat") {
          const formattedLog = this.formatMultiLineStatus(datasource, data.status, data.message);
          this.setState((prevState) => ({
            terminalLogs: [...prevState.terminalLogs, formattedLog], // Append formatted openvpn_statistics message
          }), () => {
            // Scroll to the bottom of the terminal after updating the logs
            this.scrollToBottom();
          });
        // } else {
        //   this.setState((prevState) => ({
        //     terminalLogs: [...prevState.terminalLogs, `>${data.source}: ${data.status} => ${data.message}`], // Append other messages
        //   }), () => {
        //     // Scroll to the bottom of the terminal after updating the logs
        //     this.scrollToBottom();
        //   });
        // }
      });
    });
  }
  
  formatMultiLineStatus(source, status, payload) {
    return (
      <div>
        <span>>{source}: <span style={{ color: "red" }}>{status}</span> => </span>
        {this.renderPayload(payload)}
      </div>
    );
  }

  // Recursive function to render payload, handling both strings and objects
  renderPayload(payload) {
    if (typeof payload === 'string') {
      return <span>{payload}</span>;  // If it's a string, render it
    } else if (typeof payload === 'object' && payload !== null) {
      return Object.keys(payload).map((key, index) => (
        <span key={index}>
          {key}: {typeof payload[key] === 'object' ? this.renderPayload(payload[key]) : <span style={{ color: "orange" }}>{payload[key]}</span>}
          {index < Object.keys(payload).length - 1 && ", "}
        </span>
      ));
    }
    return null;  // In case the payload is null or undefined
  }
  
  componentWillUnmount() {
    if (this.socket) {
      this.socket.close();  // Cleanup WebSocket when the component unmounts
    }
  }

  // Automatically scroll to the bottom of the terminal window
  scrollToBottom() {
    this.terminalRef.current.scrollTop = this.terminalRef.current.scrollHeight;
  }



  applyChanges() {
    Object.assign(this.props.building, this.state);
  }
  
  parseVpnFile = (content) => {
    const lines = content.split('\n');
    const vpn_info = {};

    lines.forEach(line => {
      if (line.startsWith('remote ')) {
        vpn_info.remote = line.split(' ')[1];
      } else if (line.startsWith('http-proxy ')) {
        vpn_info.httpProxy = line.split(' ')[1];
      } else if (line.startsWith('cipher ')) {
        vpn_info.cipher = line.split(' ')[1];
      }
    });

    return vpn_info;
  };

  readVpnFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileContent = e.target.result;
      this.setState({ vpn_file_content: fileContent });
      const vpn_info = this.parseVpnFile(fileContent);
      this.setState({ vpn_info });
      console.log("VPN File Content:", fileContent); // Log the file content
    };
    reader.readAsText(file);
  };

  handleVpnFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      this.setState({ vpnFile: file });
      this.readVpnFile(file); // Read and log file contents
    }
  };

  wrapper = createRef();

  render() {

    const { vpn, vpn_info, connectionStatus, terminalLogs  } = this.state;

    return (
      <div ref={this.wrapper}>
        <div className="ui form">
          <a href="http://www.knxmonitor.com/guide/" target="_blank" title="Open instructions in a new window" rel="noopener noreferrer">
            <button
              className="ui positive basic button"
              style={helpButtonStyle}
            >
              <Icon name='help' />
              Getting Started
            </button>
          </a>
          {this.state.errorMessage ? (
            <div className="ui negative message">{this.state.errorMessage}</div>
          ) : null}
          {/* Name */}
          <div className="fields">
            <div className="eight wide field">
              <label>
                Building Name
                <span className="required">*</span>
                <input
                  type="text"
                  placeholder=""
                  defaultValue={this.props.building.name}
                  onChange={(e) => {
                    let val = e.target.value.trim();
                    if (val === "") {
                      window.$(e.target).closest(".field").addClass("error");
                      return;
                    }
                    window.$(e.target).closest(".field").removeClass("error");
                    if (val !== this.props.building.name) {
                      this.setState({ name: val });
                      this.setState({ changed: true });
                    }
                  }}
                />
              </label>
            </div>
            <div className="four wide field">
              <div className="ui checkbox">
                <input
                  type="checkbox"
                  name="vpnToggle"
                  checked={this.state.vpn}
                  onChange={() => this.setState({ vpn: !this.state.vpn , changed: true })}
                />
                <label>Use VPN</label>
              </div>
            </div>
          </div>
            {/* Conditionally render VPN file upload */}
          {vpn && (
            <div className="field">
              <label>VPN Configuration File (.ovpn)</label>
              <input
                type="file"
                name="vpnFile"
                accept=".ovpn"
                onChange={this.handleVpnFileChange}
              />
              {vpn_info.remote && (
                <div>
                  <p><strong>Remote:</strong> {vpn_info.remote}</p>
                  <p><strong>HTTP Proxy:</strong> {vpn_info.httpProxy}</p>
                  <p><strong>Cipher:</strong> {vpn_info.cipher}</p>
                </div>
              )}              
            </div>
          )}

          {/* Display current connection status */}
          {connectionStatus && (
            <div className="ui message">
              <p>Current Connection Status: {connectionStatus}</p>
            </div>
          )}

          {/* Terminal window to display WebSocket logs */}
          <div
            ref={this.terminalRef}
            style={{
              backgroundColor: "black",
              color: "#00cc00",
              fontFamily: "monospace",
              padding: "10px",
              height: "200px",
              overflowY: "scroll",
              borderRadius: "5px",
              marginTop: "20px",
              border: "1px solid green"
            }}
          >
            {terminalLogs.length > 0 ? (
              terminalLogs.map((log, index) => (
                <div key={index}>{log}</div>
              ))
            ) : (
              <p>No messages yet...</p>
            )}
          </div>


          {/* IP Address/DNS Name field with dynamic label */}
          <div className="fields">
            <div className="eight wide ip_host field">
              <label>
              {vpn ? "Internal IP Address of KNX IP Router" : "IP Address or DNS name"}<span className="required">*</span>
                <input
                  type="text"
                  placeholder=""
                  defaultValue={this.props.building.ip_host}
                  onChange={(e) => {
                    let val = e.target.value.trim();
                    if (val === "") {
                      window.$(e.target).closest(".field").addClass("error");
                      return;
                    }
                    window.$(e.target).closest(".field").removeClass("error");
                    if (val !== this.props.building.ip_host) {
                      this.setState({ ip_host: val ,  changed: true });
                    }
                  }}
                />
              </label>
            </div>
            <div className="two wide ip_port field">
              <label>
                {vpn ? "IP Router Port" : "Port"}
                <input
                  type="text"
                  placeholder="3671"
                  defaultValue={this.props.building.ip_port}
                  onChange={(e) => {
                    let val = e.target.value.trim();
                    if (isFinite(+val) === false) {
                      window.$(e.target).closest(".field").addClass("error");
                      return;
                    }
                    window.$(e.target).closest(".field").removeClass("error");
                    if (val !== this.props.building.ip_port) {
                      this.setState({ ip_port: val, changed: true });
                    }
                  }}
                />
              </label>
            </div>
          </div>

          {/* IP Address
          <div className="fields">
            <div className="eight wide ip_host field">
              <label>
                IP Address or DNS name
                <span className="required">*</span>
                <input
                  type="text"
                  placeholder=""
                  defaultValue={this.props.building.ip_host}
                  onChange={(e) => {
                    let val = e.target.value.trim();
                    if (val == "") {
                      window.$(e.target).closest(".field").addClass("error");
                      return;
                    }
                    window.$(e.target).closest(".field").removeClass("error");
                    if (val != this.props.building.ip_host) {
                      this.setState({ ip_host: val });
                      this.setState({ changed: true });
                    }
                  }}
                />
              </label>
            </div>
            <div className="two wide ip_port field">
              <label>
                Port
                <input
                  type="text"
                  placeholder="3671"
                  defaultValue={this.props.building.ip_port}
                  onChange={(e) => {
                    let val = e.target.value.trim();
                    if (isFinite(+val) === false) {
                      window.$(e.target).closest(".field").addClass("error");
                      return;
                    }
                    window.$(e.target).closest(".field").removeClass("error");
                    if (val !== this.props.building.ip_port) {
                      this.setState({ ip_port: val });
                      this.setState({ changed: true });
                    }
                  }}
                />
              </label>
            </div>
          </div>
          <div className="ui ignored info message">
            <div className="header">Security</div>
            <ul className="list">
              <li>
                Configure your firewall to only accept connections from the
                following server addresses:
                <ul className="list">
                  <li>164.92.128.194  Europe</li>
                  <li>161.35.151.228  Europe</li>
                  <li>35.189.37.118   Asia/Pacific</li>
                  <li>170.64.146.93   Asia/Pacific</li>
                </ul>
              </li>
              <li>
                If your router allows port-mapping, avoid exposing port 3671.
              </li>
            </ul>
          </div>
          {/* Start/Stop/Test Connection */}
          <div className="fields">
            {this.props.building.enabled === true ? (
              <button
                className="ui negative button"
                style={connectionButtonStyle}
                onClick={() => {
                  api("/building/stop/" + this.props.building._id, {
                    method: "POST",
                  }).then(() => {
                    this.props.building.enabled = false;
                    this.forceUpdate();
                  });
                }}
              >
                Stop Monitoring
              </button>
            ) : this.props.building.enabled === undefined ? (
              <div>
                <button
                  className="ui teal testConnection button"
                  onClick={(e) => {
                    if (window.$(e.target).hasClass("disabled")) return;
                    let btn = e.target;
                    window.$(btn).addClass("disabled");
                    window.$(btn).addClass("loading");
                    let label = window
                      .$(this.wrapper.current)
                      .find(".testResult");
                    label.removeClass("green");
                    label.removeClass("red");
                    label.html("...");
                    api("/building/test", {
                      method: "POST",
                      body: JSON.stringify({
                        ip_host:
                          this.state.ip_host ||
                          window
                            .$(this.wrapper.current)
                            .find(".ip_host.field input")
                            .val(),
                        ip_port: this.state.ip_port || 3671,
                      }),
                    }).then((res) => {
                      window.$(btn).removeClass("disabled");
                      window.$(btn).removeClass("loading");

                      let label = window
                        .$(this.wrapper.current)
                        .find(".testResult");
                      label.removeClass("green");
                      label.removeClass("red");
                      if (res.success) {
                        label.addClass("green");
                        label.html("✓ Connected");
                      } else {
                        label.addClass("red");
                        label.html(res.error);
                      }
                    });
                  }}
                >
                  Test Connection
                </button>
                <span className="ui testResult basic label"></span>
              </div>
            ) : (
              <button
                className="ui positive button"
                style={connectionButtonStyle}
                onClick={() => {
                  api("/building/start/" + this.props.building._id, {
                    method: "POST",
                  }).then((res) => {
                    if (res.success === true) {
                      this.setState({ errorMessage: "" });
                      this.props.building.enabled = true;
                      this.forceUpdate();
                    } else if (res.error) {
                      this.setState({ errorMessage: res.error });
                    }
                  });
                }}
              >
                Start Monitoring
              </button>
            )}
          </div>
          <div className="ui section divider"></div>
          {/* Default source address */}
          <div className="fields">
            <div className="six wide field">
              <label>
                Source Address{" "}
                <small style={{ fontWeight: "normal" }}>
                  for sending datagram from KNXMonitor
                </small>
              </label>
              <input
                type="text"
                placeholder="1.1.255"
                defaultValue={this.props.building.knxsource}
                onChange={(e) => {
                  let val = e.target.value.trim();
                  if (val === "") {
                    window.$(e.target).closest(".field").addClass("error");
                    return;
                  }
                  window.$(e.target).closest(".field").removeClass("error");
                  if (val !== this.props.building.knxsource) {
                    this.setState({ knxsource: val, changed: true });
                  }
                }}
              />
            </div>
          </div>
          {/* Save change, hidden when new building */}
          {this.props.building._id && this.state.changed ? (
            <div className="ui segment" style={saveChangeButtonStyle}>
              <button
                className="ui positive button"
                onClick={() => {
                  // save changes
                  api("/building/update/" + this.props.building._id, {
                    method: "POST",
                    body: JSON.stringify(this.state),
                  }).then(() => {
                    Object.assign(this.props.building, this.state);
                    this.setState((prevState) => delete prevState.name);
                    this.setState({ changed: false });
                    this.forceUpdate();
                  });
                  // .catch(() => {
                  //   //console.log(err);
                  // });
                }}
              >
                Save Changes
              </button>
            </div>
          ) : undefined}
        </div>
      </div>
    );
  }
}
export default ConnectionStep;
