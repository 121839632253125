import React, { createRef } from "react";

import { chain, find } from "underscore";
import * as DPT from "./dpt";

class DPTComboBox extends React.Component {
  constructor(props) {
    super(props);

    let sizeinbit = props.sizeinbit;
    const __solveDPt = (items) => {
      return chain(items)
        .map((item) => {
          if (sizeinbit && item.sizeinbit && item.sizeinbit !== sizeinbit)
            return null;
          delete item.parent;
          if (item.children)
            // item.children = __solveDPt(item.children);
            return [item, __solveDPt(item.children)];
          else if (item.converter == undefined) return null;

          return item;
        })
        .compact()
        .flatten()
        .value();
    };

    this.state = {
      DPTList: __solveDPt(DPT.getAllTypes()),
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.defaultVal !== prevProps.defaultVal) {
      var dpt = find(
        this.state.DPTList,
        (d) => d.name == this.props.defaultVal
      );
      if (dpt) {
        var domNode = this.wrapper.current;
        window.$(domNode).find("select").val(dpt.id).trigger("change");
      }
    }
  }

  componentDidMount() {
    var domNode = this.wrapper.current;
    var opts = {
      data: this.state.DPTList,
      multiple: false,
      placeholder: "Select Data Point Type...",
      templateResult: (state) => {
        if (state.children) {
          return state.text;
        }
        let item = state;
        var $state = window.$(
          '<span class="ui label">' +
            item.text +
            '<span class="detail">' +
            item.id +
            "</span></span>" +
            (item.unit
              ? '<span class="ui tiny grey label">' + item.unit + "</span>"
              : "")
        );
        return $state;
      },
    };
    Object.assign(opts, this.props.options);

    window
      .$(domNode)
      .find(".select.field")
      .append("<select id=dptSelect' name='dpt'><option/></select>");
    window
      .$(domNode)
      .find("select")
      .select2(opts)
      .on("select2:select", (e) => {
        // this.selectedGroup = e.params.data.id
        // window.$(domNode).find('select').val(e.params.data.id).trigger('change')
        if (this.props.setSelectedDPT) {
          this.props.setSelectedDPT(e.params.data.name);
        }
      });

    if (this.props.defaultVal) {
      var dpt = find(
        this.state.DPTList,
        (d) => d.name == this.props.defaultVal
      );
      if (dpt) {
        window.$(domNode).find("select").val(dpt.id).trigger("change");
      }
    }
  }
  componentWillUnmount() {
    var domNode = this.wrapper.current;
    window.$(domNode).find("select").select2("destroy");
  }

  wrapper = createRef();

  render() {
    return (
      <div ref={this.wrapper}>
        <div className="ui form" style={this.props.style}>
          <div className="select field">
            <div>DPT: </div>
          </div>
        </div>
      </div>
    );
  }
}
export default DPTComboBox;
