import React from "react";
import moment from "moment";
import _ from "underscore";
import { Icon } from "semantic-ui-react";
import { api } from "../api";

function format_time_digit(val) {
  if (Math.floor(parseFloat(val)).toString().length === 1) return "0" + val;
  return val;
}

class CronTimer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hour: 0,
      minute: 0,
      displayType: "Daily",
      cronPrefix: "",
      inclWeekDays: [],
    };
    if (props.expression)
      Object.assign(this.state, this.fromCronExpression(props.expression));
  }

  fromCronExpression(expression) {
    var parts = expression.split(" ");
    if (parts.length !== 6) return {};
    var result = {
      hour: 0,
      minute: 0,
      displayType: "Daily",
      cronPrefix: "",
      inclWeekDays: [],
    };

    //CronPrefix
    var reg = /^\d+$/;
    for (let i = 0; i < parts[0].length; i++) {
      if (reg.test(parts[0][i])) {
        //ignore second for now
      } else {
        result.cronPrefix += parts[0][i];
      }
    }

    result.minute = parseFloat(parts[1]);
    result.hour = parseFloat(parts[2]);

    if (parts[5] === "*") {
      result.displayType = "Daily";
    } else {
      result.displayType = "Weekly";
      var weekday = parts[5].split(",");
      for (let i = 0; i < weekday.length; i++) {
        if (reg.test(weekday[i])) {
          result.inclWeekDays.push(parseInt(weekday[i]));
        }
      }
    }

    return result;
  }
  toCronExpression() {
    this.state.inclWeekDays.sort();
    return (
      this.state.cronPrefix +
      "00 " +
      format_time_digit(this.state.minute) +
      " " +
      format_time_digit(this.state.hour) +
      " " +
      "* " + //Day of month
      "* " + //Month
      (this.state.displayType !== "Weekly" ||
      this.state.inclWeekDays.length == 0
        ? "*"
        : this.state.inclWeekDays.join(","))
    );
  }

  componentDidMount() {
    if (this.props.expression) this.componentDidUpdate();
  }
  componentWillUnmount() {
    this.closed = true;
  }
  componentDidUpdate() {
    if (this._tmr_queryNextTimerDate)
      clearTimeout(this._tmr_queryNextTimerDate);

    this._tmr_queryNextTimerDate = setTimeout(() => {
      if (this.closed) return;

      var expression = this.toCronExpression();

      if (this._last_expression && this._last_expression === expression) return;

      this._last_expression = expression;
      if (this.props.onChange) {
        this.props.onChange(expression);
      }

      api("/building/" + this.props.params.buildingId + "/timers/nextTime", {
        method: "POST",
        body: JSON.stringify({ expression: expression }),
      }).then((res) => {
        if (this.closed) return;
        let result = res.result;
        if (result && result.nextDateLocal) {
          const nextDateLocal = moment(
            result.nextDateLocal,
            "YYYY-MM-DD HH:mm"
          );
          // var nextDate = moment(result.nextDate);
          // console.log(moment(result.nextDateLocal, 'YYYY-MM-DD HH:mm').format());
          this.setState({
            nextTimerDateDesc: nextDateLocal.format("llll"),
            nextTimerDateFromNow: nextDateLocal.fromNow(),
          });
        }
      });
    }, 600);
  }

  handleinclWeekDaysClick(day) {
    if (this.state.inclWeekDays.indexOf(day) > -1)
      this.setState({
        inclWeekDays: _.without(this.state.inclWeekDays, day),
      });
    else
      this.setState({
        inclWeekDays: _.union(this.state.inclWeekDays, [day]),
      });
  }

  handlePrefixClick(cronPrefix) {
    this.setState(
      {
        cronPrefix: cronPrefix,
        hour: 0,
        minute: 0,
      },
      () => {
        window.$(this.txtHour).val(format_time_digit(this.state.hour));
        window.$(this.txtMinute).val(format_time_digit(this.state.minute));
      }
    );
  }

  handlePrefixOffsetClick(offsetDir) {
    this.setState({
      cronPrefix:
        this.state.cronPrefix.replace("-", "").replace("+", "") + offsetDir,
    });
  }

  render() {
    var cronDesciption = "";
    if (this.state.cronPrefix.indexOf("Sun") === 0) {
      cronDesciption =
        " Sun" +
        (this.state.cronPrefix.indexOf("SunUp") === 0 ? "Rise" : "Set");

      if (this.state.hour === 0 && this.state.minute === 0) {
        cronDesciption = "at" + cronDesciption;
      } else {
        if (this.state.cronPrefix.indexOf("-") > 0)
          cronDesciption = " before" + cronDesciption;
        else cronDesciption = " after" + cronDesciption;
        if (this.state.minute !== 0) {
          cronDesciption = this.state.minute + " minutes" + cronDesciption;
        }
        if (this.state.hour !== 0) {
          cronDesciption = this.state.hour + " hours " + cronDesciption;
        }
      }
    }
    return (
      <div className="crontimer">
        <div
          className="nextTimerDate"
          style={{
            position: "absolute",
            top: "-3em",
            whiteSpace: "nowrap",
            backgroundColor: "rgba(40, 150, 218, 0.2)",
            color: "#00689E",
          }}
        >
          <span>&nbsp;next at </span>
          <span className="f-w-600">
            {this.state.nextTimerDateDesc
              ? this.state.nextTimerDateDesc
              : "..."}
          </span>
          <span>, </span>
          <span>{this.state.nextTimerDateFromNow}&nbsp;</span>
        </div>
        <select
          className="ui dropdown"
          style={{ height: "2em", padding: "0.3em", margin: "2em 0" }}
          defaultValue={this.state.displayType}
          onChange={(e) => {
            this.setState({
              displayType: e.target.value,
            });
          }}
          onBlur={(e) => {
            this.setState({
              displayType: e.target.value,
            });
          }}
        >
          <option value="Daily">Daily</option>
          <option value="Weekly">Weekly</option>
        </select>

        {this.state.displayType === "Weekly" ? (
          <div className="ui mini weekdays buttons">
            <button
              className={
                "ui button" +
                (this.state.inclWeekDays.indexOf(1) > -1 ? " active" : "")
              }
              onClick={this.handleinclWeekDaysClick.bind(this, 1)}
            >
              Mon
            </button>
            <button
              className={
                "ui button" +
                (this.state.inclWeekDays.indexOf(2) > -1 ? " active" : "")
              }
              onClick={this.handleinclWeekDaysClick.bind(this, 2)}
            >
              Tue
            </button>
            <button
              className={
                "ui button" +
                (this.state.inclWeekDays.indexOf(3) > -1 ? " active" : "")
              }
              onClick={this.handleinclWeekDaysClick.bind(this, 3)}
            >
              Wed
            </button>
            <button
              className={
                "ui button" +
                (this.state.inclWeekDays.indexOf(4) > -1 ? " active" : "")
              }
              onClick={this.handleinclWeekDaysClick.bind(this, 4)}
            >
              Thu
            </button>
            <button
              className={
                "ui button" +
                (this.state.inclWeekDays.indexOf(5) > -1 ? " active" : "")
              }
              onClick={this.handleinclWeekDaysClick.bind(this, 5)}
            >
              Fri
            </button>
            <button
              className={
                "ui button" +
                (this.state.inclWeekDays.indexOf(6) > -1 ? " active" : "")
              }
              onClick={this.handleinclWeekDaysClick.bind(this, 6)}
            >
              Sat
            </button>
            <button
              className={
                "ui button" +
                (this.state.inclWeekDays.indexOf(0) > -1 ? " active" : "")
              }
              onClick={this.handleinclWeekDaysClick.bind(this, 0)}
            >
              Sun
            </button>
          </div>
        ) : undefined}

        <div className="clockpiece">
          <div className="clock hour ui small input">
            <input
              ref={(ref) => {
                this.txtHour = ref;
              }}
              defaultValue={format_time_digit(this.state.hour)}
              onBlur={(e) => {
                let val = 0;
                if (window.$.isNumeric(e.target.value)) val = +e.target.value;

                if (val == 24) val = 0;
                if (val > 24) val = 23;
                if (val < 0) val = 0;

                window.$(this.txtHour).val(format_time_digit(val));
                this.setState({
                  hour: val,
                });
              }}
            />
          </div>
          <div className="clock minute ui small input">
            <input
              ref={(ref) => {
                this.txtMinute = ref;
              }}
              defaultValue={format_time_digit(this.state.minute)}
              onBlur={(e) => {
                let val = 0;
                if (window.$.isNumeric(e.target.value)) val = +e.target.value;

                if (val > 59) val = 59;
                if (val < 0) val = 0;

                window.$(this.txtMinute).val(format_time_digit(val));
                this.setState({
                  minute: val,
                });
              }}
            />
          </div>
        </div>

        <div className="cronDesciption">{cronDesciption}</div>

        <div className="ui small icon buttons crontype">
          <button
            title="Clock HH:MM"
            onClick={this.handlePrefixClick.bind(this, "")}
            className={
              "ui button " + (this.state.cronPrefix === "" ? "active" : "")
            }
          >
            <Icon name="clock outline" />
          </button>
          <button
            onClick={this.handlePrefixOffsetClick.bind(this, "-")}
            className={
              "ui button " +
              (this.state.cronPrefix.indexOf("Sun") === 0
                ? "sunBefore" +
                  (this.state.cronPrefix.indexOf("-") > 0 ? " active" : "")
                : "hide")
            }
          >
            before
          </button>
          <button
            onClick={this.handlePrefixOffsetClick.bind(this, "+")}
            className={
              "ui button " +
              (this.state.cronPrefix.indexOf("Sun") === 0
                ? "sunAfter" +
                  (this.state.cronPrefix.indexOf("-") < 0 ? " active" : "")
                : "hide")
            }
          >
            after
          </button>
          <button
            title="Sun Rise Offset"
            onClick={this.handlePrefixClick.bind(this, "SunUp")}
            className={
              "ui button suntype " +
              (this.state.cronPrefix.indexOf("SunUp") === 0 ? "active" : "")
            }
          >
            <Icon name="sun outline" />
          </button>
          <button
            title="Sun Set Offset"
            onClick={this.handlePrefixClick.bind(this, "SunDn")}
            className={
              "ui button suntype " +
              (this.state.cronPrefix.indexOf("SunDn") === 0 ? "active" : "")
            }
          >
            <Icon name="moon outline" />
          </button>
        </div>
      </div>
    );
  }
}

export default CronTimer;
