import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";

import { api } from "../../api";

import "./default.css";

const TopBar = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [email, setEmail] = useState("");

  useEffect(() => {
    api("/user").then((res) => {
      if (res && res.email) {
        setEmail(res.email);
        if (res.subscription_status && res.subscription_status !== "active") {
          history.push("/subscription");
        }
      }
    });
  }, []);

  return (
    <div className="TopBar">
      <div className="content">
        {email === "ibt_demo@knxmonitor.com" ? (
          <div className="Logo">
            ibt<span style={{ color: "#bcd410" }}>group</span>
          </div>
        ) : (
          <div className="Logo">
            <span style={{ color: "#64dd17", textShadow: "0 0 1px #068A00" }}>
              KNX
            </span>
            Monitor
          </div>
        )}
        {props.loggedIn ? (
          <ul>
            <li className={location.pathname === "/buildings" ? "active" : ""}>
              <Link to="/buildings">My Buildings</Link>
            </li>
            {props.building !== undefined ? (
              <li
                className={
                  window.location.pathname.indexOf("/building/") > -1
                    ? "active"
                    : ""
                }
              >
                <Link to={"/building/" + props.building._id}>
                  {props.building.name}
                </Link>
              </li>
            ) : undefined}
          </ul>
        ) : undefined}

        <div
          style={{
            width: "10em",
            position: "fixed",
            top: "0px",
            right: 0,
          }}
        >
          <div
            className="ui fluid selection dropdown"
            style={{
              backgroundImage: "linear-gradient(#F1F1F1,#DEDEDE 85%,#E2E2E2)",
              border: "0",
              height: "39px",
              borderRadius: "0",
            }}
          >
            <Dropdown text="Account">
              <Dropdown.Menu style={{ position: "fixed" }}>
                <Link className="item" to={"/subscription"}>
                  Subscription
                </Link>
                <Dropdown.Divider />
                <button
                  className="item"
                  style={{ background: "#333", color: "white" }}
                  onClick={() => {
                    localStorage.removeItem("accessToken");
                    props.setLoggedIn(false);
                  }}
                >
                  Log out
                </button>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
