import React, { useState, createRef } from "react";

import { Icon } from "semantic-ui-react";

import { api } from "../../api";
import DPTComboBox from "../DPTComboBox/DPTComboBox";

const MultiGroupPanel = ({ style, groupSelect, buildingId, onChanged }) => {
  const [editingMode, setEditingMode] = useState(false);
  const [selectedDPT, setSelectedDPT] = useState(null);
  const [saving, setSaving] = useState(false);

  const saveGroup = () => {
    let groupsToUpdate = {
      addresses: [],
      dpt: selectedDPT,
    };
    for (let i in groupSelect) {
      groupsToUpdate.addresses.push(groupSelect[i].id);
    }

    setSaving(true);

    // submit changes
    api("/building/groups/" + buildingId, {
      method: "POST",
      body: JSON.stringify(groupsToUpdate),
    })
      .then(() => {
        if (onChanged) {
          onChanged();
        }
        setSaving(false);
        setEditingMode(false);
      })
      .catch(() => {
        alert("ERROR: Invalid Parameters");
        setSaving(false);
      });
  };

  const wrapper = createRef();

  return (
    <div className="ui segments" style={style} ref={wrapper}>
      <div className="ui segment" style={{ minHeight: 46 }}>
        <span
          className="ui blue ribbon label"
          style={{
            position: "absolute",
            left: -15,
            top: "0.8em",
            backgroundColor: "#009FDA!important",
          }}
        >
          Selection
        </span>
        <b style={{ marginLeft: "5em", marginTop: "0.1em" }}>
          {`Selection of ${groupSelect.length} devices`}
        </b>

        <button
          className="ui mini labeled icon button"
          style={{ float: "right" }}
          onClick={() => {
            setEditingMode(!editingMode);
          }}
        >
          <Icon name="write" />
          {editingMode ? "Close" : "Edit"}
        </button>
      </div>
      {editingMode ? (
        <div className="ui secondary groupEdit segment">
          <DPTComboBox
            style={{ marginTop: "1em" }}
            defaultVal={getDefaultDPT(groupSelect)}
            sizeinbit={getCommonDPT(groupSelect)}
            setSelectedDPT={setSelectedDPT}
          />

          <div className="ui form" style={{ marginTop: "1em" }}>
            <div className="field">
              <button
                className="ui green labeled icon button"
                onClick={() => saveGroup()}
              >
                <Icon name="checkmark" />
                Save
              </button>
            </div>
          </div>
          <div className={`ui inverted dimmer ${saving ? "active" : ""}`}>
            <div className="ui text loader">Saving</div>
          </div>
        </div>
      ) : (
        <div
          className="ui secondary groupStatus segment"
          style={{ minHeight: "6em" }}
        >
          <span>Selected Groups:</span>
          <div className="multiSelectSelectionTable">
            <ul>
              <li>Address</li>
              <li>Name</li>
              <li>DPT</li>
            </ul>
            {groupSelect.map(
              ({ address, name, _dpt = { text: "", id: "" } }) => (
                <ul key={address}>
                  <li>{address}</li>
                  <li>{name}</li>
                  <li>{`${_dpt.text} ${_dpt.id ? `(${_dpt.id})` : ""}`}</li>
                </ul>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const getDefaultDPT = (groups) => {
  groups.map((group) => group.dpt).reduce((prev, cur) => (prev ? prev : cur));
};

const getCommonDPT = (groups) => {
  return groups
    .map((group) => group._com.sizeinbit)
    .reduce((common, dpt) =>
      common ? (dpt ? (common === dpt ? common : "invalid") : common) : dpt
    );
};

export default MultiGroupPanel;
